import React from 'react';
const browser = typeof window !== 'undefined' && window;
interface IProps {
  path?: string;
  default?: boolean;
}

const NotFound: React.FC<IProps> = (props: IProps) => {
  return browser && <div>{/* <h1>Not Found</h1> */}</div>;
};

// noinspection JSUnusedGlobalSymbols used by Gatsby
export default NotFound;
